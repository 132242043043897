export const colors = {
  simple: {
    blue: '#759DFE',
    grey: '#e6e6e5',
    pink: '#eab396',
  },
  headfirst: {
    green: '#246E74',
    red: '#742A24',
    greenDisabled: '#92b7ba',
    redDisabled: '#BA9592',
    greenBackgroundActive: '#3F8085',
    redBackgroundActive: '#85443F',
    orange: '#F26419',
  },
  pure: {
    white: '#ffffff',
    black: '#000000',
  },
  secondaryButton: {
    initial: '#01206C',
    pressed: '#0032ae',
  },
  redButton: {
    initial: '#eb4e3d',
    pressed: '#86190d',
  },
  error: {
    red: '#eb4e3d',
  },
  card: {
    mine: '#E9F1F1',
  },
  offWhite: '#cfd1e1',
  backgroundGrey: '#f4f7f9',
  selectedGrey: '#728596',
  grey: '#a5a5a5',
  dateBackgroundGrey: '#F1F1F1',
  steel: '#9dadba',
  border: 'rgba(0,0,0,0.2)',
  border1: '#d5d5d9',

  //TODO: - Move the above into the following categories
  brand: {
    primary: '#2182BD',
    secondary: '#5282BD',
    muted: '#C6DAF7',
  },
  ui: {
    primary: '#262626',
    secondary: '#757575',
    tertiary: '#F1F1F1',
    quaternary: '#FFFFFF',
    disabled: '#DEDEDE',
    error: '#D0421B',
    success: '#138000',
  },
  bg: {
    primary: '#FFFFFF',
    secondary: '#F1F1F1',
  },
  text: {
    primary: '#262626',
    secondary: '#757575',
    disabled: '#9C9C9C',
    inverse: '#FFFFFF',
    error: '#D0421B',
    success: '#138000',
  },
};
